﻿import { get, getData } from "@/Utils";
import { StatusCodes } from "http-status-codes";

function initUnSubscribeFromNewsLetter() {
  get("unsubscribeBtn")?.addEventListener("click",
    (e) => {
      const btn = (e.target as HTMLButtonElement);

      const callback = (response: Response) => {
        if (response.status === StatusCodes.OK) {
          return response.text().then(text => {
            window.location.href = text;
          });
        }
        else if (response.status === StatusCodes.IM_A_TEAPOT) {
          return response.text().then(text => {
            get("divUnSubscribe").outerHTML = text;
          });
        }
      }

      const id = btn.getAttribute("data-participationid");
      const url = btn.getAttribute("data-url") + "/" + id;
      getData(url, callback);
    });
}

function initView() {
  initUnSubscribeFromNewsLetter();
}

window.addEventListener("load", () => {
  initView();
});